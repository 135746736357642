import { graphql } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { hashCode, toPlainText } from "../lib/helpers";
import { LogoPartnership } from "../templates/prospect";
import { SiteContext } from "../components/global/site-context";
import { Quotables, SectionWrapper } from "../components/sections";
import { InlineWidget } from "react-calendly";
import { trackPath } from "../../helpers";
import { CustomerLogosExtended } from "../components/sections/logos";
import { Image } from "../components/sections/featured-media";
import { Button } from "../components/button";

const CALENDLY_URL =
  "https://calendly.com/d/4zc-jxz-5k5/30-minute-common-room-introduction";
const PAGE_WIDTH = "large";

export const query = graphql`
  query CompanyTemplateQuery($id: String!) {
    doc: sanityCompany(id: { eq: $id }) {
      _type
      _id
      title
      categories {
        _id
      }
      url
      slug {
        current
      }
      mainImageSolid {
        ...SanityImageSimple
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImageSimple
      }
      screenshots {
        ...SanityImageSimple
      }
    }
  }
`;

const CompanyTemplate = ({ data }) => (
  <LayoutContainer doc={data.doc}>
    <PageHeadMeta
      title={`Hello ${data.doc.title}`}
      description={toPlainText(data.doc._rawExcerpt)}
      image={data.doc.mainImage}
      doc={data.doc}
    />
    {data.doc && <CompanyPage {...data.doc} blocks={data.blocks} />}
  </LayoutContainer>
);

export default CompanyTemplate;

export const CompanyPage = ({
  title,
  mainImageSolid,
  screenshots,
  // blocks,
  url,
}) => {
  const site = useContext(SiteContext);
  const password = hashCode(title);
  const [visible, setVisible] = useState();

  useEffect(() => {
    if (typeof window === "undefined") return;
    const urlParams = new URLSearchParams(window.location.search);

    if (parseInt(urlParams.get("password")) === password) {
      setVisible(true);
    }
  }, []);

  const screenshot = screenshots[0];
  const screenshotWidth = screenshot
    ? screenshots?.[0].asset?.metadata.dimensions.width > 600
      ? 600
      : Math.round(screenshots?.[0].asset?.metadata.dimensions.width / 1.5)
    : null;

  return (
    <>
      <SectionWrapper
        {...{
          noHeaders: true,
          background: "whiteGlow",
          name: "header",
          backgroundCentered: true,
          padding: "tight",
          container: PAGE_WIDTH,
        }}
      >
        <div className="my-12 mt-24 text-center">
          <div className="flex justify-center items-center">
            <LogoPartnership
              logo={mainImageSolid}
              company={title}
              url={url}
              className="rounded-2xl shadow-lg bg-white"
            />
          </div>
          <h1 className="text-2xl md:text-4xl font-semibold tracking-tight my-12 first:mt-0">
            Discover hidden accounts from {title} GitHub activity.
          </h1>
          <div
            className="flex justify-center items-center"
            style={{ minHeight: 300 }}
          >
            {visible ? (
              <div className="relative pb-6">
                <div className="absolute right-0 left-0 bottom-0 h-24 bg-gradient-to-t from-white to-transparent"></div>
                {screenshots ? (
                  <Image
                    image={screenshot}
                    width={screenshotWidth}
                    className="rounded-2xl"
                  />
                ) : (
                  <p>Missing image</p>
                )}
                <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                  <Button
                    text="Book 30 minute demo"
                    bgColor="bg-link"
                    isBig={true}
                    href="#book-demo"
                  />
                </div>
              </div>
            ) : (
              <div className="text-3xl font-bold my-12 p-8 bg-dark-10 rounded-lg">
                Password required
              </div>
            )}
          </div>
          <p className="mt-4 text-secondary">
            See the people and context behind these and other engaged accounts.
          </p>
        </div>
      </SectionWrapper>

      <SectionWrapper padding="none" container={PAGE_WIDTH}>
        <div className="grid md:grid-cols-2 gap-8 pt-16" id="book-demo">
          <div>
            <p className="text-4xl font-semibold my-4 mb-8">
              Explore how you can...
            </p>
            <ul className="list-disc ml-4 text-lg">
              <li className="mb-4">
                <span className="font-semibold">Identify</span> accounts and
                prospects behind activity.
              </li>
              <li className="mb-4">
                <span className="font-semibold">Get real-time alerts</span> when
                target accounts show intent.
              </li>
              <li className="mb-4">
                <span className="font-semibold">Operationalize</span> follow-up
                with CRM integrations.
              </li>
              <li className="mb-4">
                <span className="font-semibold">
                  Connect more integrations{" "}
                </span>{" "}
                to power efficient growth!
              </li>
              <li>
                <span className="">Plus 👏so 👏much 👏more!</span>
              </li>
            </ul>
          </div>
          <InlineWidget
            url={`${CALENDLY_URL}?hide_gdpr_banner=1`}
            utm={{
              utmMedium: "prospect",
              utmSource: "book-demo",
              utmContent: trackPath(site?.doc),
            }}
            pageSettings={{
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: "8062B0",
              //   textColor: "4d5055",
            }}
            styles={{ height: "700px" }}
          />
        </div>
      </SectionWrapper>

      <SectionWrapper
        container={PAGE_WIDTH}
        header="Top open-source companies are in the know."
        layout="centered"
        padding="bottom"
      >
        <CustomerLogosExtended section={{ category: "coss" }} />
      </SectionWrapper>

      <SectionWrapper container={PAGE_WIDTH} padding="none">
        <Quotables section={{ category: "coss" }} />
      </SectionWrapper>

      <SectionWrapper
        container={PAGE_WIDTH}
        header="Ready for fresh prospects?"
        layout="centered"
      >
        <div className="text-center">
          <Button
            text="Book 30 minute demo"
            bgColor="bg-link"
            isBig={true}
            href="#book-demo"
          />
        </div>
        <p className="mt-24 text-sm text-secondary text-center">
          <a href="https://clearbit.com" target="_blank">
            {title} logo provided by Clearbit
          </a>
        </p>
      </SectionWrapper>
    </>
  );
};

export const bookDemoBlock = [
  {
    _type: "block",
    style: "normal",
    children: [
      {
        _type: "span",
        marks: ["buttonLink"],
        text: "Book demo below",
        _key: "123abc",
      },
    ],
    markDefs: [
      {
        _key: "buttonLink",
        _type: "link",
        href: `#book-demo`,
        style: "buttonBlack",
        icon: "none",
      },
    ],
  },
];
