import { graphql } from "gatsby";
import React from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { toPlainText } from "../lib/helpers";
import { Quotables, SectionWrapper } from "../components/sections";
import { CustomerLogosExtended } from "../components/sections/logos";
import { Button } from "../components/button";
import PortableText from "../components/portableText";
import { VideoPopover } from "../components/video-popover";
import { ImageBuilder } from "../components/global/image-builder";

const PAGE_WIDTH = "large";

const categoryMap = new Map([
  ["sales", "sales"],
  ["coss", "coss"],
  ["marketing", "coss"],
]);

export const query = graphql`
  query ProspectTemplateQuery($id: String!) {
    doc: sanityProspect(id: { eq: $id }) {
      _type
      _id
      title
      categories {
        _id
        slug {
          current
        }
      }
      url
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawContent(resolveReferences: { maxDepth: 5 })
      ctaText
      loom
      mainImage {
        ...SanityImageSimple
      }
      color
      quotes {
        ...Quotable
      }
      companies {
        ...Company
      }
      companiesExclude {
        _id
      }
      screenshots {
        ...SanityImageSimple
      }
      chilipiper
    }
  }
`;

const ProspectTemplate = ({ data }) => (
  <LayoutContainer doc={{ ...data.doc, backgroundColor: "lightLavender" }}>
    <PageHeadMeta
      title={`Hello ${data.doc.title}`}
      description={toPlainText(data.doc._rawExcerpt)}
      image={data.doc.mainImage}
      doc={data.doc}
    />
    {data.doc && <ProspectPage {...data.doc} />}
  </LayoutContainer>
);

export default ProspectTemplate;

export const ProspectPage = ({
  title,
  url,
  mainImage,
  categories,
  _rawExcerpt,
  _rawContent,
  loom,
  ctaText,
  chilipiper,
  companies,
  companiesExclude,
  quotes,
}) => {
  const tagSlug =
    categories.length > 0
      ? categoryMap.has(categories[0].slug.current)
        ? categoryMap.get(categories[0].slug.current)
        : categories[0].slug.current
      : null;

  return (
    <>
      <SectionWrapper
        {...{
          noHeaders: true,
          background: "whiteGlow",
          name: "header",
          backgroundCentered: true,
          padding: "tight",
          container: "narrow",
        }}
      >
        <div className="my-12 mt-24 text-center">
          <div className="flex justify-center items-center">
            <LogoPartnership
              logo={mainImage}
              company={title}
              url={url}
              className="rounded-2xl shadow-lg bg-white"
            />
          </div>
          <h1 className="text-lg md:text-xl font-medium md:leading-normal tracking-tight mt-8 mb-4 first:mt-0">
            <PortableText blocks={_rawExcerpt} />
          </h1>
          <VideoPopover
            loomUrl={loom}
            //image
            //mobileImage
            alt={ctaText}
            //previewVideo
            // buttonText={ctaText}
            videoCtaText={ctaText}
            previewGif="https://cdn.loom.com/sessions/thumbnails/b4e00c29a6634dff9f53051539eba5b6-with-play.gif"
          />
        </div>
      </SectionWrapper>

      <SectionWrapper padding="bottom" container={PAGE_WIDTH}>
        <div className="grid md:grid-cols-2/5-3/5 gap-10" id="book-demo">
          <div>
            <PortableText blocks={_rawContent} />
          </div>
          <div
            className="border border-dark-10 p-3 rounded-xl overflow-hidden text-center"
            style={{ background: "rgb(250, 250, 251)" }}
          >
            <iframe
              src={chilipiper}
              className="w-full"
              style={{ minHeight: 780 }}
            ></iframe>
          </div>
        </div>
      </SectionWrapper>

      <SectionWrapper
        container={PAGE_WIDTH}
        header="Fast growing companies already know."
        layout="centered"
        padding="bottom"
      >
        <CustomerLogosExtended
          section={{
            category: tagSlug,
            includes: companies,
            excludes: companiesExclude,
            cards: 12,
          }}
        />
      </SectionWrapper>

      <SectionWrapper container={PAGE_WIDTH} padding="none">
        <Quotables
          section={{
            category: tagSlug,
            quotes,
            limit: 3,
          }}
        />
      </SectionWrapper>

      <SectionWrapper
        container={PAGE_WIDTH}
        header="Ready for fresh prospects?"
        layout="centered"
      >
        <div className="text-center">
          <Button
            text="Book 30 minute demo"
            bgColor="bg-link"
            isBig={true}
            href="#book-demo"
          />
        </div>
        <p className="mt-24 text-sm text-secondary text-center">
          <a href="https://clearbit.com" target="_blank">
            {title} logo provided by Clearbit
          </a>
        </p>
      </SectionWrapper>
    </>
  );
};

export const bookDemoBlock = [
  {
    _type: "block",
    style: "normal",
    children: [
      {
        _type: "span",
        marks: ["buttonLink"],
        text: "Book demo below",
        _key: "123abc",
      },
    ],
    markDefs: [
      {
        _key: "buttonLink",
        _type: "link",
        href: `#book-demo`,
        style: "buttonBlack",
        icon: "none",
      },
    ],
  },
];

export const LogoPartnership = ({ logo, company, className, url }) => (
  <>
    {logo ? (
      <ImageBuilder
        image={logo}
        width={112}
        alt={`${company} logo`}
        className={className}
      />
    ) : (
      <ImageBuilder
        src={`https://logo.clearbit.com/${url}`}
        width={112}
        className={`bg-white ${className}`}
      />
    )}
    <ImageBuilder src="/static/img/link-horizontal.svg" className="w-12 mx-6" />
    <ImageBuilder src="/static/img/logo-square.svg" width={112} />
  </>
);
